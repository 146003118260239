import { callServiceAPI } from './callServiceAPI.js';

export function downloadOrigin(body) {
  const url = 'translation/v1/sp/downloadOrigin';
  return callServiceAPI(url, { body }, 3000);
}

export function taskList(body) {
  const url = 'translation/v1/sp/taskList';
  return callServiceAPI(url, { header: {}, body }, 3000);
}

export function taskDetails(body) {
  const url = 'translation/v1/sp/taskDetails';
  return callServiceAPI(url, { header: {}, body }, 3000);
}

export function uploadReply(body) {
  const url = 'translation/v1/sp/uploadReply';
  return callServiceAPI(url, { body }, 3000);
}

export function storagePath(body) {
  const url = 'translation/v1/storagePath';
  return callServiceAPI(url, { body }, 3000);
}

export function appraise(body) {
  const url = 'translation/v1/sp/appraise';
  return callServiceAPI(url, { body }, 3000);
}

export function getLanguagesCP() {
  const url = 'translation/v1/cp/getLanguages';
  return callServiceAPI(url, { header: {}, body: {} }, 3000);
}

export function getConfigs(ruleType, ruleNames) {
  const url = 'translation/v1/cp/getConfigs';
  return callServiceAPI(
    url,
    {
      header: {},
      body: {
        ruleType,
        ruleNames
      }
    },
    3000
  );
}

export function getPrivacyList(body, zoneId) {
  const url = 'translation/v1/sp/queryPrivacy';
  return callServiceAPI(url, { body }, 3000, zoneId);
}

export function setPrivacy(body, zoneId) {
  const url = 'translation/v1/sp/privacy';
  return callServiceAPI(url, { body }, 3000, zoneId);
}
