export const callServiceAPI = (url, headerBody, timeData, zoneId) => new Promise((resolve, reject) => {
  AGC.callServiceAPI(
    url,
    headerBody,
    'POST',
    zoneId,
    timeData,
    res => {
      resolve(res);
    },
    error => {
      reject(error);
    }
  );
});

export const getcallServiceAPI = (url, timeData, zoneId) => new Promise((resolve, reject) => {
  AGC.callServiceAPI(
    url,
    { header: {}, body: '' },
    'GET',
    zoneId,
    timeData,
    res => {
      resolve(res);
    },
    error => {
      reject(error);
    }
  );
});
