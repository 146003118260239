import * as Utils from '@appstore/agc-utils';
// eslint-disable-next-line no-undef
const isDevelopment = () => process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';

/**
 * 打点服务,该方法仅支持点击事件，不支持PV
 * @param {object} obj 一般用来传递打点中特性化的数据,可根据实际情况自己拓展
 */
export const interactiveHaLog = obj => {
  if (isDevelopment()) {
    return;
  }
  const { title, tagType, translationServiceId } = obj;
  if (translationServiceId === '') {
    Utils.sendClickData(title, tagType);
  } else {
    Utils.sendClickData(title, tagType, { translationServiceId });
  }
};

export const interactiveHaLogLanguage = obj => {
  if (isDevelopment()) {
    return;
  }
  const { title, tagType, Data } = obj;
  Utils.sendClickData(title, tagType, { Data });
};
